import React from 'react';
import GoToTop from '../components/GoToTop';

const Blog = () => {
    return (
        <>
        {window.location.assign('https://blog.dandytravelco.com')}
        </>
    );
};

export default Blog;