import Button from 'react-bootstrap/Button';
import './NavBar.scss'
const PlanYourTrip = () => {
    return (
        <>
        <Button href='Plan-Your-Trip' className='btn' id='trip-btn'>PLAN YOUR <br /> TRIP</Button>{' '}
        </>
    )
}

export default PlanYourTrip;